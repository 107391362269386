import { gql } from '@apollo/client';

import { useContactSupportLink_TenantFragment } from './graphql-types/useContactSupportLink_TenantFragment';
import { useContactSupportLink_UserFragment } from './graphql-types/useContactSupportLink_UserFragment';

export const useContactSupportLink = (
  user?: useContactSupportLink_UserFragment | null,
  tenant?: useContactSupportLink_TenantFragment | null
): {
  contactSupportLink: string;
  contactSupportText: string;
} => {
  return tenant?.customContactSupportLinkV2
    ? {
        contactSupportLink: tenant.customContactSupportLinkV2.url,
        contactSupportText: tenant.customContactSupportLinkV2.text,
      }
    : {
        contactSupportLink: `mailto:support@tradewing.com`,
        contactSupportText: 'Contact Support',
      };
};

useContactSupportLink.userFragment = gql`
  fragment useContactSupportLink_UserFragment on User {
    _id
    firstName
    lastName
    email
  }
`;

useContactSupportLink.tenantFragment = gql`
  fragment useContactSupportLink_TenantFragment on Tenant {
    _id
    associationCode
    customContactSupportLinkV2 {
      text
      url
    }
  }
`;
