import { MenuItem, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';

import { CodeOfConductModal } from '@/components/CodeOfConductModal';
import { useCurrent } from '@/hooks/useCurrent';
import { BookOpenIcon } from '@/imports/ui/chakra/feather';

export const CodeOfConductMenuItem: React.FC<{}> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const current = useCurrent();

  if (current.loading || !current.tenant?.codeOfConduct || !current.user) {
    return null;
  }

  return (
    <>
      <MenuItem icon={<BookOpenIcon />} onClick={onOpen}>
        Code of Conduct
      </MenuItem>
      <CodeOfConductModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
