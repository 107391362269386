import { NextRouter, useRouter } from 'next/router';

export const getRouteMatch = (
  href: string | null,
  router: NextRouter,
  options?: { isExact?: boolean }
): boolean => {
  if (href === null) {
    return false;
  }

  const path = router.asPath.split('?')[0];

  return options?.isExact
    ? path === href
    : path === href || path.startsWith(`${href}/`);
};

export const useRouteMatch = (
  href: string | null,
  options?: { isExact?: boolean }
): boolean => {
  const router = useRouter();

  return getRouteMatch(href, router, options);
};
