import { MenuItem, MenuItemProps, useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';

import { useBookmarkOnMobileDeviceModal } from '@/components/BookmarkOnMobileDeviceModal';
import { SmartphoneIcon } from '@/imports/ui/chakra/feather';

export const BookmarkOnMobileDeviceMenuItem: React.FC<MenuItemProps> = ({
  ...menuItemProps
}) => {
  const router = useRouter();
  const { onOpen } = useBookmarkOnMobileDeviceModal();

  return (
    <MenuItem icon={<SmartphoneIcon />} onClick={onOpen} {...menuItemProps}>
      Add to Mobile Device
    </MenuItem>
  );
};
