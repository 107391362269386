import { useCurrent } from '@/hooks/useCurrent';

export const useFeatureFlags = () => {
  const current = useCurrent();

  const ads = !!current.tenant?.featureFlags?.ads;
  const reporting = !!current.tenant?.featureFlags?.reporting;
  const topSessionBanner = !!current.tenant?.featureFlags?.topSessionBanner;
  const disableFtue = !!current.tenant?.featureFlags?.disableFtue;
  const historicalDataImport =
    !!current.tenant?.featureFlags?.historicalDataImport;
  const conferences = !!current.tenant?.featureFlags?.conferences;
  const mobileGrowth = !!current.tenant?.featureFlags?.mobileGrowth;
  const cms = !!current.tenant?.featureFlags?.cms;
  const setReferrerPolicy = !!current.tenant?.featureFlags?.setReferrerPolicy;
  const invoicing = !!current.tenant?.featureFlags?.invoicing;

  return {
    ads,
    reporting,
    topSessionBanner,
    disableFtue,
    conferences,
    historicalDataImport,
    mobileGrowth,
    cms,
    setReferrerPolicy,
    invoicing,
  };
};
