import { useCurrent } from '@/hooks/useCurrent';
import { usePermissions } from '@/hooks/usePermissions';

export const useMemberManagement = (): {
  isActive: boolean;
  loading: boolean;
} => {
  const current = useCurrent();
  const permissions = usePermissions();

  const isMemberManagementFeatureFlagOn =
    !!current.tenant?.featureFlags?.memberManagement;
  const isMemberManagementAdminOnlyFeatureFlagOn =
    !!current.tenant?.featureFlags?.memberManagementAdminOnly;
  const isMemberManagementPaid =
    !!current.tenant?.paidModules?.memberManagement;

  const isActive =
    (isMemberManagementPaid && isMemberManagementFeatureFlagOn) ||
    (isMemberManagementAdminOnlyFeatureFlagOn &&
      permissions.canDoAdminStuffAndAuthenticated);

  return {
    isActive,
    loading: current.loading || permissions.isLoading,
  };
};
