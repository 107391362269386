import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';

import { Redraft } from '@/components/Redraft';
import { useCurrent } from '@/hooks/useCurrent';
import { Emoji } from '@/imports/ui/chakra/components/Emoji';
import { Prose } from '@/imports/ui/chakra/components/Prose';
import { useImpersonation } from '@/modules/accounts/impersonation';

import {
  CodeOfConductModal_AcceptCodeOfConduct,
  CodeOfConductModal_AcceptCodeOfConductVariables,
} from './graphql-types/CodeOfConductModal_AcceptCodeOfConduct';

const ACCEPT_CODE_OF_CONDUCT = gql`
  mutation CodeOfConductModal_AcceptCodeOfConduct(
    $input: AcceptCodeOfConductInput!
  ) {
    acceptCodeOfConduct(input: $input) {
      user {
        _id
        acceptedCodeOfConduct
      }
    }
  }
`;

export const CodeOfConductModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const current = useCurrent();
  const { impersonationActive } = useImpersonation();
  const [checked, setChecked] = React.useState(
    current.user?.acceptedCodeOfConduct ? true : false
  );

  const [acceptCodeOfConduct, acceptCodeOfConductResult] = useMutation<
    CodeOfConductModal_AcceptCodeOfConduct,
    CodeOfConductModal_AcceptCodeOfConductVariables
  >(ACCEPT_CODE_OF_CONDUCT);

  const showClose =
    !current.tenant?.obnoxiousCodeOfConduct ||
    !!current.user?.acceptedCodeOfConduct;

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={showClose}
      /** Backdoor for admins to impersonate users without accepting the CoC for them */
      closeOnEsc={impersonationActive || showClose}
      isCentered
      size={['full', 'full', 'xl']}
    >
      <ModalOverlay />
      <ModalContent>
        {showClose && <ModalCloseButton color="black" />}
        <ModalHeader textAlign="center" pt="10" pb="6">
          <VStack spacing="2">
            <Emoji symbol="📃" fontSize="4xl" />
            <Heading size="md">
              {!current.user?.acceptedCodeOfConduct && (
                <>{`Please Read and Accept the `}</>
              )}
              {`${current.tenant?.displayName} Code of Conduct`}
            </Heading>
            <Text color="gray.600" fontWeight="normal">
              Help us cultivate a community we all enjoy!
            </Text>
          </VStack>
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" pb="6">
          <Box
            bg="gray.100"
            p="4"
            h="xs"
            overflow="auto"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="xl"
          >
            {current.tenant?.codeOfConduct && (
              <Prose>
                <Redraft rawDraftContentState={current.tenant?.codeOfConduct} />
              </Prose>
            )}
          </Box>
        </ModalBody>
        <ModalFooter pt="2" pb="6">
          {!current.user?.acceptedCodeOfConduct ? (
            <Flex w="full" justify="space-between">
              <Checkbox
                isDisabled={!!current.user?.acceptedCodeOfConduct}
                isChecked={checked}
                onChange={() => setChecked((oldValue) => !oldValue)}
                mr="2"
              >
                {`I've read and accepted the code of conduct`}
              </Checkbox>
              <Button
                isDisabled={!checked || !!current.user?.acceptedCodeOfConduct}
                isLoading={acceptCodeOfConductResult.loading}
                onClick={async () => {
                  if (checked) {
                    await acceptCodeOfConduct({
                      variables: { input: { isPartOfOnboardingFlow: false } },
                    });
                    props.onClose();
                  }
                }}
              >
                Accept
              </Button>
            </Flex>
          ) : (
            <Button onClick={props.onClose}>Okay</Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
