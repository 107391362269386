import { DocumentNode, gql } from '@apollo/client';
import {
  IconProps,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagProps,
} from '@chakra-ui/react';
import * as React from 'react';

import { MembershipStatus } from '@/graphql-types/globalTypes';
import { AwardIcon, UserIcon } from '@/imports/ui/chakra/feather';

import { MembershipStatusTag_MembershipDataFragment } from './graphql-types/MembershipStatusTag_MembershipDataFragment';

export const MembershipStatusTag: React.FC<
  {
    membershipData: MembershipStatusTag_MembershipDataFragment;
  } & TagProps
> & { membershipDataFragment: DocumentNode } = ({
  membershipData,
  ...tagProps
}) => {
  if (membershipData.membershipStatus === MembershipStatus.NOT_A_MEMBER) {
    return (
      <CustomTag color="gray.500" leftIcon={UserIcon} {...tagProps}>
        NOT A MEMBER
      </CustomTag>
    );
  }

  if (membershipData.membershipStatus === MembershipStatus.LAPSED) {
    return (
      <CustomTag color="red.500" leftIcon={UserIcon} {...tagProps}>
        LAPSED
      </CustomTag>
    );
  }
  return (
    <CustomTag color="yellow.500" leftIcon={AwardIcon} {...tagProps}>
      MEMBER
    </CustomTag>
  );
};

const CustomTag: React.FC<
  {
    leftIcon: React.FC<IconProps>;
  } & TagProps
> = ({ children, leftIcon, ...tagProps }) => {
  return (
    <Tag bg="none" fontWeight="extrabold" {...tagProps}>
      <TagLeftIcon as={leftIcon} />
      <TagLabel>{children}</TagLabel>
    </Tag>
  );
};

MembershipStatusTag.membershipDataFragment = gql`
  fragment MembershipStatusTag_MembershipDataFragment on MembershipData {
    membershipStatus
  }
`;
