import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';

import { AddAsBookmarkSteps } from '@/components/AddAsBookmarkSteps';
import { usePermissions } from '@/hooks/usePermissions';

import { BookmarkOnMobileDeviceModal_Tenant } from './graphql-types/BookmarkOnMobileDeviceModal_Tenant';

import { QRCode } from './QRCode';

const QUERY = gql`
  query BookmarkOnMobileDeviceModal_Tenant {
    tenant {
      result {
        _id
        acronym
        absoluteUrl
      }
    }
  }
`;

const BookmarkOnMobileDeviceModalContext = React.createContext<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
} | null>(null);

export const useBookmarkOnMobileDeviceModal = () => {
  const context = React.useContext(BookmarkOnMobileDeviceModalContext);

  if (!context) {
    throw new Error(
      'useBookmarkOnMobileDeviceModal() must be inside of <BookmarkOnMobileDeviceModalProvider/>'
    );
  }

  return context;
};

const _BookmarkOnMobileDeviceModal: React.FC = () => {
  const { canAccessCommunityAndGroupFeed } = usePermissions();
  const query = useQuery<BookmarkOnMobileDeviceModal_Tenant>(QUERY);

  const absoluteUrl = query.data?.tenant.result?.absoluteUrl;

  const href = absoluteUrl
    ? canAccessCommunityAndGroupFeed
      ? `${absoluteUrl}/community?isBookmarkOnMobileDeviceModalOpen=true`
      : `${absoluteUrl}?isBookmarkOnMobileDeviceModalOpen=true`
    : undefined;

  const { isOpen, onClose } = useBookmarkOnMobileDeviceModal();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader textAlign="center" px="6" pb="0" py="16">
          Add the {query.data?.tenant.result?.acronym || 'association'}{' '}
          Community to your Mobile Home Screen
        </ModalHeader>
        <ModalBody p="6">
          <Text
            textAlign="center"
            display={{ base: 'none', md: 'block' }}
            mb="6"
          >
            To get started, scan the QR code below and log in.
          </Text>

          <Box display={{ base: 'block', md: 'none' }}>
            <AddAsBookmarkSteps />
          </Box>

          <Center display={{ base: 'none', md: 'flex' }}>
            {href && <QRCode value={href} />}
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="outline" colorScheme="gray">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const BookmarkOnMobileDeviceModalProvider: React.FC = (props) => {
  const router = useRouter();

  // careful - this currently works because we are *inside* the restricted route already
  const { onOpen, onClose, isOpen } = useDisclosure({
    defaultIsOpen:
      !router.pathname.startsWith('/login') &&
      router.query.isBookmarkOnMobileDeviceModalOpen === 'true',
  });

  React.useEffect(() => {
    if (isOpen && router.query.isBookmarkOnMobileDeviceModalOpen === 'true') {
      const { isBookmarkOnMobileDeviceModalOpen, ...rest } = router.query;
      router.replace({ query: rest });
    }
  }, [isOpen, router]);

  return (
    <BookmarkOnMobileDeviceModalContext.Provider
      value={{ onOpen, onClose, isOpen }}
    >
      {props.children}
      <_BookmarkOnMobileDeviceModal />
    </BookmarkOnMobileDeviceModalContext.Provider>
  );
};
