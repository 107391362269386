import { gql, useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import { MembershipStatusTag } from '@/components/MembershipStatusTag';
import { MembershipStatus } from '@/graphql-types/globalTypes';
import { useContactSupportLink } from '@/hooks/useContactSupportLink';
import { useLogout } from '@/hooks/useLogout';
import { useMemberManagement } from '@/hooks/useMemberManagement';
import { usePermissions } from '@/hooks/usePermissions';
import {
  AwardIcon,
  BriefcaseIcon,
  Edit2Icon,
  HelpCircleIcon,
  HomeIcon,
  LogOutIcon,
  UserIcon,
} from '@/imports/ui/chakra/feather';
import { useFeatureFlags } from '@/imports/ui/hooks/useFeatureFlags';
import { useImpersonation } from '@/modules/accounts/impersonation';

import { AccountMenu_Query } from './graphql-types/AccountMenu_Query';

import { BookmarkOnMobileDeviceMenuItem } from './components/BookmarkOnMobileDeviceMenuItem';
import { CodeOfConductMenuItem } from './components/CodeOfConductMenuItem';

const QUERY = gql`
  query AccountMenu_Query {
    currentUser {
      _id
      canAccessCommunity
      displayName
      rosterOrganization {
        _id
        currentUserOrganizationPermission {
          canManageOrganizationProfile
          canManageOrganizationMembership
        }
      }
      proxyProfilePictureUrl(
        input: { w: 140, h: 140, fit: "facearea", facepad: 10.0 }
      )
      membershipData {
        ...MembershipStatusTag_MembershipDataFragment
      }
      ...useContactSupportLink_UserFragment
    }

    myDraftPostCount

    tenant {
      result {
        _id
        ...useContactSupportLink_TenantFragment
      }
    }
  }
  ${MembershipStatusTag.membershipDataFragment}
  ${useContactSupportLink.userFragment}
  ${useContactSupportLink.tenantFragment}
`;

export const AccountMenu: React.FC = () => {
  const router = useRouter();
  const logoutFunc = useLogout();
  const { mobileGrowth } = useFeatureFlags();
  const memberManagement = useMemberManagement();
  const query = useQuery<AccountMenu_Query>(QUERY);
  const { impersonationActive, stopImpersonation } = useImpersonation();
  const permissions = usePermissions();
  const currentUserId = query.data?.currentUser?._id;
  const canViewAdminSite =
    !!currentUserId && permissions.canDoAdminStuffAndAuthenticated;
  const onAdminSite = router.asPath.startsWith('/admin');

  const { contactSupportLink, contactSupportText } = useContactSupportLink(
    query.data?.currentUser,
    query.data?.tenant.result
  );

  return (
    <Box>
      <Menu>
        <MenuButton cursor="pointer" aria-label="account menu">
          <Avatar
            boxSize="10"
            ignoreFallback
            name={query.data?.currentUser?.displayName}
            src={query.data?.currentUser?.proxyProfilePictureUrl ?? undefined}
          />
        </MenuButton>

        <MenuList>
          <Stack direction="row" p="2">
            <Avatar
              name={query.data?.currentUser?.displayName}
              src={query.data?.currentUser?.proxyProfilePictureUrl ?? undefined}
            />

            <Stack spacing="0">
              <Text textStyle="xlBodyStrong" flexGrow="1" noOfLines={1}>
                {query.data?.currentUser?.displayName}
              </Text>
              {memberManagement.isActive &&
                query.data?.currentUser?.membershipData?.membershipStatus ===
                  MembershipStatus.MEMBER && (
                  <MembershipStatusTag
                    membershipData={query.data.currentUser.membershipData}
                    p="0"
                  />
                )}
            </Stack>
          </Stack>

          <MenuGroup>
            <Box p="2">
              <NextLink href="/me/profile">
                <Button as={MenuItem} variant="outline" colorScheme="gray">
                  Edit your Profile
                </Button>
              </NextLink>
            </Box>
          </MenuGroup>

          {(query.data?.currentUser?.rosterOrganization
            ?.currentUserOrganizationPermission.canManageOrganizationProfile ||
            (query.data?.currentUser?.rosterOrganization
              ?.currentUserOrganizationPermission
              .canManageOrganizationMembership &&
              memberManagement.isActive)) && (
            <MenuGroup title="MY COMPANY">
              {query.data.currentUser.rosterOrganization
                .currentUserOrganizationPermission
                .canManageOrganizationProfile && (
                <NextLink href={`/my-company/dashboard/overview`}>
                  <MenuItem icon={<BriefcaseIcon />}>Company Profile</MenuItem>
                </NextLink>
              )}
              {query.data.currentUser.rosterOrganization
                .currentUserOrganizationPermission
                .canManageOrganizationMembership &&
                memberManagement.isActive && (
                  <NextLink href={`/my-company/membership`}>
                    <MenuItem icon={<AwardIcon />}>Company Membership</MenuItem>
                  </NextLink>
                )}
            </MenuGroup>
          )}

          <MenuGroup title="YOUR ACCOUNT">
            {!!currentUserId && (
              <NextLink href={`/drafts`}>
                <MenuItem icon={<Edit2Icon />}>
                  {canViewAdminSite ? 'Scheduled Posts & Drafts' : 'Drafts'}
                  {!!query.data?.myDraftPostCount && (
                    <Tag
                      ml="2"
                      px="4"
                      background="primary.500"
                      color="white"
                      borderRadius="xl"
                      fontWeight="semibold"
                    >
                      {query.data.myDraftPostCount}
                    </Tag>
                  )}
                </MenuItem>
              </NextLink>
            )}
            {memberManagement.isActive && (
              <NextLink href="/me/membership">
                <MenuItem icon={<AwardIcon />}>Membership</MenuItem>
              </NextLink>
            )}
            {!canViewAdminSite ? null : onAdminSite ? (
              <NextLink href="/community">
                <MenuItem icon={<HomeIcon />}>Member Site</MenuItem>
              </NextLink>
            ) : (
              <NextLink href="/admin/membership/people">
                <MenuItem icon={<UserIcon />}>Admin Site</MenuItem>
              </NextLink>
            )}
          </MenuGroup>
          <Divider my="2" />
          <MenuGroup title="LINKS">
            <MenuItem
              as="a"
              target="_blank"
              rel="noopener"
              href={contactSupportLink}
              icon={<HelpCircleIcon />}
            >
              {contactSupportText}
            </MenuItem>

            <CodeOfConductMenuItem />
            {mobileGrowth && <BookmarkOnMobileDeviceMenuItem />}
          </MenuGroup>
          <Divider my="2" />
          {impersonationActive && (
            <MenuItem
              icon={<LogOutIcon />}
              onClick={async () => {
                await stopImpersonation();
              }}
            >
              Stop Impersonating
            </MenuItem>
          )}
          <MenuItem
            icon={<LogOutIcon />}
            onClick={async () => {
              await logoutFunc();
            }}
            color="red.600"
          >
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
