import { gql, useApolloClient, useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import Cookies from 'universal-cookie';

import { logError } from '@/imports/logging/ClientLogger';
import { accountsClient } from '@/modules/accounts/accounts-client';
import {
  ACCESS_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
} from '@/modules/accounts/tokenStorage';
import { useMessages } from '@/modules/messaging';

import { UseLogout_SingleSignOutQuery } from './graphql-types/UseLogout_SingleSignOutQuery';

const cookie = new Cookies();

const LEGACY_METEOR_LOGIN_TOKEN_KEY = 'Meteor.loginToken';
const LEGACY_METEOR_LOGIN_TOKEN_EXPIRES_KEY = 'Meteor.loginTokenExpires';
const LEGACY_METEOR_USER_ID_KEY = 'Meteor.userId';

const SINGLE_SIGN_OUT_QUERY = gql`
  query UseLogout_SingleSignOutQuery {
    tenant {
      result {
        _id
        ssoMetadata {
          singleSignOutRedirectURL
        }
      }
    }
  }
`;

export const useLogout = (isLogoutFromURL?: boolean): (() => Promise<void>) => {
  const messages = useMessages();
  const apolloClient = useApolloClient();
  const singleSignOutQueryResult = useQuery<UseLogout_SingleSignOutQuery, {}>(
    SINGLE_SIGN_OUT_QUERY,
    {
      skip: isLogoutFromURL,
    }
  );

  // Note, this is technically racey because the user could click the logout menu item
  // before the query is fetched, but its unlikely to be a problem in practice and we
  // need to get this shipped quickly.
  const singleSignOutUrl =
    singleSignOutQueryResult?.data?.tenant.result?.ssoMetadata
      ?.singleSignOutRedirectURL;

  const doRedirect = (): void => {
    const redirect = new URLSearchParams(window.location.search).get(
      'redirect'
    );

    if (isLogoutFromURL && !!redirect) {
      // for customer that wants to trigger a TW logout upon their ams logout
      window.location.href = redirect;
    } else if (!isLogoutFromURL && singleSignOutUrl) {
      window.location.href = singleSignOutUrl;
    } else {
      window.location.href = '/login';
    }
  };

  return async () => {
    try {
      localStorage && localStorage.removeItem(LEGACY_METEOR_USER_ID_KEY);
      localStorage && localStorage.removeItem(LEGACY_METEOR_LOGIN_TOKEN_KEY);
      localStorage &&
        localStorage.removeItem(LEGACY_METEOR_LOGIN_TOKEN_EXPIRES_KEY);
      cookie.remove('meteor_login_token', {
        path: '/',
      });
      //ensure token cookies are removed before accounts log out
      cookie.remove(ACCESS_TOKEN_COOKIE_KEY, {
        path: '/',
      });
      cookie.remove(REFRESH_TOKEN_COOKIE_KEY, {
        path: '/',
      });
      messages.client?.disconnectUser();
      await accountsClient.logout();
      await apolloClient.clearStore();
      datadogRum.clearUser();
    } catch (err) {
      logError(err);
    } finally {
      doRedirect();
    }
  };
};
